import { Form, Button, Stack, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { fetchTicket } from 'app/api'
import { useAppSelector } from 'app/hooks'
import Scan from 'components/common/Scan'
import TicketItem from 'components/content/ticket/TicketItem'
import Notifier from 'components/notifier/Notifier'
import {
    clearNotification,
    setNotification,
} from 'components/notifier/notifierSlice'
import { TicketScanResult } from './types'
import { setRoomIdFromScanResult } from './cleaningSlice'
import { useState } from 'react'

const TicketScanPage = () => {
    const [bsRoomId, setBsRoomId] = useState('')
    const dispatch = useDispatch()

    const response = useAppSelector(state => state.cleaning.ticket)
    const notification = useAppSelector(state => state.notifier)

    const handleScan = async (decodedText: string) => {
        const decodedObj: TicketScanResult = JSON.parse(decodedText)

        dispatch(setRoomIdFromScanResult(decodedObj))

        if (!decodedObj.bsRoomId) {
            dispatch(
                setNotification({
                    message: 'Ungültiger Reinigung QR-Code.',
                    variant: 'danger',
                })
            )

            return false
        }

        const result = await fetchTicket(decodedObj.bsRoomId, dispatch)

        if (!result) {
            return false
        }

        dispatch(clearNotification())

        return true
    }

    const handleManual = async () => {
        const result = await fetchTicket(bsRoomId, dispatch)

        if (!result) {
            return false
        }

        dispatch(clearNotification())

        return true
    }

    return response && response.ticket && response.room ? (
        <TicketItem response={response} />
    ) : (
        <Stack direction='vertical' gap={2} className='align-items-center'>
            {notification.message && (
                <Notifier
                    message={notification.message}
                    variant={notification.variant}
                />
            )}
            <div className='text-center mb-2 text-color-n4'>
                <small>
                    Bitte scannen Sie hier den QR-Code der Wohneinheit.
                </small>
            </div>
            <Scan handleScan={handleScan} />
            <Form>
                <InputGroup className='pt-4 my-4'>
                    <Form.Control
                        style={{
                            borderTopLeftRadius: '1.5em',
                            borderBottomLeftRadius: '1.5em',
                        }}
                        type='text'
                        id='roomId-input'
                        value={bsRoomId}
                        className='text-color-n3'
                        placeholder='Wohneinheit-Id'
                        onChange={e => setBsRoomId(e.target.value)}
                    />
                    <Button
                        variant='primary'
                        id='roomId-submit'
                        onClick={handleManual}
                    >
                        Ticket anzeigen
                    </Button>
                </InputGroup>
            </Form>
        </Stack>
    )
}

export default TicketScanPage
