import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './app/store'

import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import './assets/css/ds-include.css'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </StrictMode>
)
