import { Dispatch, SetStateAction } from 'react'
import { Button, Modal, Stack } from 'react-bootstrap'

import { updateTicketStatusDone } from 'app/api'
import { useAppDispatch } from 'app/hooks'

interface UpdateConfirmationProps {
    providerId: string
    ticketId: string
    showModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    setShowTicket: Dispatch<SetStateAction<boolean>>
}

const UpdateTicketConfirmation = ({
    providerId,
    ticketId,
    showModal,
    setShowModal,
    setShowTicket,
}: UpdateConfirmationProps) => {
    const dispatch = useAppDispatch()

    return (
        <Modal show={showModal} centered className='ds-modal align-self-center'>
            <Modal.Header>
                <h4>Aktion bestätigen</h4>
            </Modal.Header>
            <Modal.Body>
                <span>
                    Beachten Sie, dass Ihre Aktion nicht rückgängig gemacht
                    werden kann.
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction='horizontal' gap={2} className='w-100'>
                    <Button
                        type='submit'
                        variant='secondary'
                        onClick={() => {
                            setShowModal(false)
                            setShowTicket(true)
                        }}
                        aria-label='Passwort-Änderung abbrechen'
                        className='w-50'
                    >
                        Abbrechen
                    </Button>
                    <Button
                        variant='primary'
                        aria-label='Passwort ändern'
                        className='w-50'
                        onClick={() => {
                            updateTicketStatusDone(
                                providerId,
                                ticketId,
                                dispatch
                            )
                            setShowModal(false)
                        }}
                    >
                        <Stack
                            direction='horizontal'
                            gap={2}
                            className='justify-content-center'
                        >
                            <span>Bestätigen</span>
                        </Stack>
                    </Button>
                </Stack>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateTicketConfirmation
