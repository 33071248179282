import { Row } from 'react-bootstrap'

const Header = () => {
    return (
        <header className='container ds-bg-secondary'>
            <Row>
                <h4 className='ds-company-name'>Task Manager</h4>
                <span className='pb-6'>Reinigung, Hausmeister, Wäscherei</span>
            </Row>
        </header>
    )
}

export default Header
