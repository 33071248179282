import TicketScanPage from 'components/content/ticket/TicketScanPage'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'
import NavBar from 'components/nav/NavBar'

const App = () => {
    return (
        <>
            <NavBar />
            <Header />
            <section className='container ds-bg-secondary pt-5 pb-4'>
                <TicketScanPage />
                <Footer />
            </section>
        </>
    )
}

export default App
